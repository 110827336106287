<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button 
            v-if="$allowPermission('agent:manage.promotion')"
            to="/promotions/create" variant="primary">
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>
    <b-overlay :show="isFetchingPromotions">
      <b-card>
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row align-v="center" class="mb-3">
          <b-col cols="12" md="4">
            <LimitSelect v-model="selectedLimit"/>
          </b-col>
          <b-col cols="12" md="8">
            <b-row>
              <b-col>
                <!-- <b-input-group>
                  <b-form-input
                    v-model="search"
                    type="search"
                    class="mx-1"
                    :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
                  ></b-form-input>
                </b-input-group> -->
              </b-col>
              <b-col v-if="isOwner">
                <MasterSelectInput 
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
                />
              </b-col>
              <b-col v-if="isOwner || isMaster">
                <AgentsSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
                />
              </b-col>
              <b-col>
                <PromotionForGameTypeSelectInput
                  :value="selectedForGameType"
                  hide-label
                  @update="onForGameTypeChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-table :fields="fields" :items="filteredList" responsive show-empty>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(orderPosition)="data">
            <b-form-select
              :options="orderOption"
              :value="data.value"
              @change="(value) => changePromotionOrder(data.item, value)"
            ></b-form-select>
          </template>
          <template #cell(type)="data">
            {{ displayPromotionType(data.value) }}
            <span v-if="data.value === 10" class="d-block badge badge-success">{{ data.item.bonusName }}</span>
            <!-- <br /> -->
            <!-- <small v-if="data.value === 4" class="text-primary"
              >เริ่มตั้งแต่เวลา
              <b-badge>
                {{
                  `${
                    String(data.item.startHour).length === 1
                      ? `0${data.item.startHour}`
                      : data.item.startHour
                  }:${
                    String(data.item.startMin).length === 1
                      ? `0${data.item.startMin}`
                      : data.item.startMin
                  }`
                }}
              </b-badge>
              ถึงเวลา
              <b-badge>
                {{
                  `${
                    String(data.item.endHour).length === 1
                      ? `0${data.item.endHour}`
                      : data.item.endHour
                  }:${
                    String(data.item.endMin).length === 1
                      ? `0${data.item.endMin}`
                      : data.item.endMin
                  }`
                }}
              </b-badge>
              ของทุกวัน
            </small> -->
          </template>
          <template #cell(bonusType)="data">
            <span v-if="data.value === 0"> เปอร์เซ็นต์ </span>
            <span v-else-if="data.value === 1"> ค่าคงที่ </span>
            <span v-else-if="data.value === 2"> สุ่ม </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(forGameType)="data">
            <span v-if="data.value === 0"> ทุกเกม </span>
            <span v-else-if="data.value === 1"> สล็อต </span>
            <span v-else-if="data.value === 2"> ยิงปลา </span>
            <span v-else-if="data.value === 3"> คาสิโน </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(turnType)="data">
            <span v-if="data.value === 0"> เทิร์นเครดิต </span>
            <span v-else-if="data.value === 1"> เทิร์นโอเวอร์ </span>
            <span v-else-if="data.value === 2"> ไม่มีเทิร์น </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(imageUrl)="data">
            <b-button size="sm" variant="primary" @click="showImage(data.value)"><i class="uil uil-eye"></i></b-button>
          </template>
          <template #cell(isActive)="data">
            <b-badge :variant="data.value? 'success': 'danger'">
              {{ data.value? $t('utils.status.active'): $t('utils.status.inactive')}}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button-group
              v-if="$allowPermission('agent:manage.promotion')"
            >
              <b-button
                class="btn btn-info btn-sm"
                type="button"
                :to="`/promotions/detail/${data.value}`"
              >
                {{ $t('buttons.edit') }}
              </b-button>
              <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="onRemovePromotion(data.value)"
              >
                {{ $t('buttons.remove') }}
              </button>
            </b-button-group>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
          </template>
        </b-table>
        <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการโปรโมชั่น',
  },
  components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    PromotionForGameTypeSelectInput: () =>
      import('@components/promotions/for-game-type-select-input'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedMaster: '',
      selectedAgent: '',
      selectedForGameType: '',
      search: '',
      fields: [
        '#',
        {
          key: 'title',
          label: this.$t('promotion.name'),
        },
        {
          key: 'type',
          label: this.$t('fields.type'),
        },
        {
          key: 'bonusType',
          label: this.$t('promotion.bonusType'),
        },
        {
          key: 'forGameType',
          label: 'ชนิดเกม',
        },
        {
          key: 'turnType',
          label: 'ประเภทเทิร์น',
          class: 'text-center',
        },
        {
          key: 'turnRate',
          label: this.$t('cashback.turnover_point'),
          class: 'text-right',
        },
        {
          key: 'orderPosition',
          label: this.$t('promotion.order_position'),
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'imageUrl',
          label: 'รูปภาพ',
          class: 'text-center',
        },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingPromotions: (state) => state.promotion.isFetchingPromotions,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'promotions', 'userInfo']),
    filteredList() {
      return this.promotions.items.filter((item) => item.type !== 9)
    },
    orderOption() {
      return [...Array(this.filteredList?.length + 1).keys()].map((i) => i)
    },
    pagination() {
      return this.promotions.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPromotions', 'updatePromotion', 'updatePromotionOrder', 'deletePromotion']),
    fetchData() {
      this.fetchPromotions({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
        gameType: this.selectedForGameType,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onForGameTypeChange(forGameType) {
      this.selectedForGameType = forGameType
      this.fetchData()
    },
    displayPromotionType(type) {
      switch (type) {
        case 0:
          return 'ฟรีเครดิต'
        case 1:
          return 'ฝากครั้งแรก'
        case 2:
          return 'ฝากครั้งแรกของวัน'
        case 3:
          return 'ทุกยอดฝาก'
        case 4:
          return 'นาทีทอง'
        case 5:
          return 'กงล้อ'
        case 6:
          return 'เปิดไพ่'
        case 7:
          return 'เช็คอิน'
        case 8:
          return 'Welcome Back'
        case 9:
          return 'แนะนำเพื่อนจากยอดฝาก'
        case 10:
          return 'โบนัสพิเศษ'
        default:
          return '-'
      }
    },
    onRemovePromotion(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePromotion(id)
          }
        })
    },
    changePromotionOrder(item, position) {
      if (item.orderPosition !== position) {
        this.updatePromotionOrder({
          id: item.id,
          data: { orderPosition: position },
        })
      }
    },
    showImage(imageUrl) {
      const h = this.$createElement
        const messageVNode = h('img', { class: ['w-100'], attrs: { src: imageUrl }})
        this.$bvModal.msgBoxOk([messageVNode], {
          buttonSize: 'sm',
          centered: true,
          okTitle: 'ปิด',
        })
    }
  },
}
</script>
